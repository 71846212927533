import React from 'react'
import './css/App.css';


const ItemBox = ({src, alt, name, onClick}) => {
      return (
        <div className='box_outline' onClick={onClick}>
          <div className='item_img_div'>
            <img src={src} alt={alt} />
          </div>
            <h1 className='item_heading'>{name}</h1>
        </div>
      )
}

export default ItemBox