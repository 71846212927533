import React, { useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import './css/App.css';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Axios from '../config/axios';
import FilePicker from './FilePicker';



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: "10px",
    width: "60%",
    p: 4,
};


const EditModal = ({ handleClose, open, singleData }) => {
    const [data, setData] = useState({
        itemName: '',
        manufacturer: '',
        serialNo: '',
        description: '',
        photos: []
    })
    const handleSaveEdit = () => {
        Axios.post(`/looser/items/${singleData?.id}/`, {
            name: data.name,
            manufacturer: data.manufacturer,
            serial_no: data.serialNo,
            description: data.description,
            photos: [{ photo: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAAXNSR0IArs4c6QAAAF5JREFUGFcdzCEWwyAQQMG/olSzCOj9rxOuACZ5IYKsTis2L7UjRpa6eNJEeAWu34VNQ1rvbudJLpljDKImZN1WB/lDyQUXkNa6m01K/rCPgSZFaq0eo/IOz/nF5uQGrQ0tstJXUxoAAAAASUVORK5CYII=" }],
            deleted_photos: [8]
        }).then((res) => console.log(res)).catch((err) => console.log(err))
    }

    const uploadInputRef = useRef();
    useEffect(() => {
        if (singleData) {
            setData({
                itemName: singleData?.name,
                manufacturer: singleData?.manufacturer,
                serialNo: singleData?.serial_no,
                description: singleData?.description,
                photos: singleData?.photos?.map((item) => item.photo),
                images: singleData?.photos,

            })
        }
    }, [singleData])
    const handleInputChange = (e) => {
        setData({ [e.target.name]: e.target.value })
    }
    console.log({ uploadInputRef }, "uploadInputRef");

    const handleFile = (e) => {
        e.stopPropagation();
        const files = e.target?.files;
        // const newImages = files?.map((item) => ({ photo: item }));
        const images = [];
        for (let i = 0; i < Object.keys(files).length; i++) {
            images.push(URL.createObjectURL(e.target?.files[i]));
        }
        setData({
            ...data,
            photos: [...data?.photos, ...images],
        });
    };

    console.log("Edited data ", data)

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Update Item
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <div className='all_fields'>
                        <p>Name</p>
                        <input className='input_field' onChange={handleInputChange} value={data?.itemName} name="itemName" type="text" />
                        <p>Manufacturer</p>
                        <input className='input_field' onChange={handleInputChange} value={data?.manufacturer} name="manufacturer" type="text" />
                        <p>Serial No</p>
                        <input className='input_field' onChange={handleInputChange} value={data?.serialNo} name="serialNo" type="text" />
                        <p>Description</p>
                        <textarea className='text_field' onChange={handleInputChange} value={data?.description} name="description" cols="50" rows="5"></textarea>
                        {/* <input
                            ref={uploadInputRef} accept="image/*" type="file" id='photo_file' style={{ display: 'none' }} />
                        <label htmlFor="photo_file" className='photo_upload'>
                            <AddOutlinedIcon sx={{ color: "rgb(10, 63, 116)" }} />
                        </label> */}
                        <FilePicker onChange={handleFile} fileName={data.photos} />
                        <button
                            onClick={handleSaveEdit}
                            style={{
                                backgroundColor: "#0a3f74"
                            }}
                            className='found_btn'>Save
                        </button>
                    </div>
                </Typography>
            </Box>
        </Modal>
    )
}

export default EditModal