/* eslint-disable jsx-a11y/alt-text */
import { getCurrentPosition } from 'geolocation';
import React, { useEffect, useState } from 'react';
import { useCookies } from "react-cookie";
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Axios from '../../config/axios';
import { Store, UpdateStore } from '../../StoreContext';
import { determineDeviceType } from '../../utils/functions';
import BasicModal from './locationPopup';
import Geocode from "react-geocode";
import { firebaseConfig } from '../../config/firebase';
import mixpanel from 'mixpanel-browser';

Geocode.setApiKey(firebaseConfig.apiKey);

function LostItem() {
  let { uid } = useParams();
  const navigate = useNavigate();
  const { user, loggedIn } = Store();
  const [lostItem, setLostItem] = useState({});
  const [mainImage, setMainImage] = useState();
  const [cookies, setCookie] = useCookies(['uid'])
  const updateStore = UpdateStore();
  const [open, setOpen] = useState(false)
  const [error, setError] = useState(null);
  const [latitude, setLatitude] = useState()
  const [longitude, setLongitude] = useState()
  const getLostItems = async (latitude, longitude) => {
    await Geocode.fromLatLng(latitude, longitude).then(
      (response) => {
        setLongitude(longitude)
        setLatitude(latitude)
        const address = response?.results[0]?.formatted_address;

        Axios.post(`/looser/find-item/${uid}/`, {
          latitude,
          longitude,
          location: address.slice(0, 255)
        })
          .then((response) => {
            console.log(response.data, 'lat')
            if (Object.values(response?.data || {}).length === 0) {
              let device_type = determineDeviceType()
              let device = {
                iOS: () => {
                  window.location.href = "https://testflight.apple.com/join/PK1Ua4LJ"
                },
                Android: () => {
                  window.location.href = "https://play.google.com/store/apps/details?id=com.withered_feather_36062"
                },
                web: () => {

                  navigate('/about', {
                    state: { error: true },
                  });
                }
              }[device_type]
              device()
              return
            }

            if (!response.data) {
              window.location = '/*';
            }
            setLostItem(response?.data);
            localStorage.setItem('itemDetail', JSON.stringify(response?.data));
            if (response?.data?.photos?.length) {
              setMainImage(response?.data?.photos[0]?.photo);
            }
          })
          .catch((error) => {
            let device_type = determineDeviceType()
            let device = {
              iOS: () => {
                window.location.href = "https://testflight.apple.com/join/PK1Ua4LJ"
              },
              Android: () => {
                window.location.href = "https://play.google.com/store/apps/details?id=com.withered_feather_36062"
              },
              web: () => {

                navigate('/about', {
                  state: { error: true },
                });
              }
            }[device_type]
            device()
          });
        console.log(address);
      },
      (error) => {
        console.error(error);
      }
    );
  };
  const getLostItemsWithoutLoc = async () => {
    Axios.post(`/looser/find-item/${uid}/`)
      .then((response) => {
        console.log(response.data, 'lat')
        if (Object.values(response?.data || {}).length === 0) {
          let device_type = determineDeviceType()
          let device = {
            iOS: () => {
              window.location.href = "https://testflight.apple.com/join/PK1Ua4LJ"
            },
            Android: () => {
              window.location.href = "https://play.google.com/store/apps/details?id=com.withered_feather_36062"
            },
            web: () => {

              navigate('/about', {
                state: { error: true },
              });
            }
          }[device_type]
          device()
          return
        }

        if (!response.data) {
          window.location = '/*';
        }
        setLostItem(response?.data);
        localStorage.setItem('itemDetail', JSON.stringify(response?.data));
        if (response?.data?.photos?.length) {
          setMainImage(response?.data?.photos[0]?.photo);
        }
      })
      .catch((error) => {
        let device_type = determineDeviceType()
        let device = {
          iOS: () => {
            window.location.href = "https://testflight.apple.com/join/PK1Ua4LJ"
          },
          Android: () => {
            window.location.href = "https://play.google.com/store/apps/details?id=com.withered_feather_36062"
          },
          web: () => {

            navigate('/about', {
              state: { error: true },
            });
          }
        }[device_type]
        device()
      });
  }

  const handleFoundButton = () => {
    if (lostItem?.status === 'lost') {
      Axios.post(`/looser/items/${lostItem?.id}/mark-found/`)
        .then((res) => {
          getLostItems(latitude, longitude)
          toast.success(res?.data?.status);
        })
        .catch((err) => console.log(err));
    } else {
      Axios.post(`/looser/items/${lostItem?.id}/mark-lost/`)
        .then((res) => {
          getLostItems(latitude, longitude)
          toast.success(res?.data?.status);
        })
        .catch((err) => console.log(err));
    }
  };
  console.log(lostItem, 'lt')
  useEffect(() => {
    console.log('on2')
    if (lostItem?.status === 'lost') {
      console.log("on")
      mixpanel.track('Lost Item', {
        itemName: lostItem?.name,
        itemId: lostItem?.item_id,
        status: lostItem?.status,
        // belongs_to: lostItem?.user_id
      });
    }
  }, [lostItem])

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          getLostItems(position.coords.latitude, position.coords.longitude)
        },
        (error) => {
          getLostItemsWithoutLoc()
          setError(error.message);
        }
      );
    } else {
      setError('Geolocation is not supported by this browser.');
      toast.error(error)
    }
  }, [])


  useEffect(() => {
    if (cookies?.uid) {
      setOpen(false)
    } else {
      setOpen(true)
    }
  }, []);

  const handleClose = () => {
    if (uid) {
      setCookie('uid', uid)
      updateStore({ cookies })
      setOpen(false);
    }
  };
  const handleDeclineClose = () => {
    setOpen(false);
  };
  const [Thumbnail, ...photos] = lostItem?.photos || [];
  return (
    <>
      {open && <div className="CookieBanner_Div">
        <div className="CookieBanner_TypoDiv">
          <p className="CookieBanner_desp">
            We Use our own and third-party cookies to personalize content and to analyze web traffic.
          </p>
        </div>
        <div className="CookieBanner_BtnDiv">
          <button onClick={handleClose} className="CookieBanner_AgreeBtn">
            Agree
          </button>
          <button onClick={handleDeclineClose} className="CookieBanner_DeclineBtn" >
            Decline
          </button>
        </div>
      </div>}
      <div className='LostItem_Container'>
        <h2>{lostItem?.name}</h2>

        <div className='LostItem_BodyDiv'>
          <div className='LostItem_ImageDiv'>
            <div className='LostItem_UpperImageDiv'>
              <img src={Thumbnail?.photo} />
            </div>
            {photos.length > 0 &&
              <div className='LostItem_LowerImageDiv'>
                {photos?.map((item) => {
                  return (
                    <img
                      onClick={() => setMainImage(item?.photo)}
                      src={item?.photo}
                    />
                  );
                })}
              </div>
            }
          </div>
          <div className='LostItem_TextDiv'>
            <div className='LostItem_TitleDiv'>
              <h3>Lost Item</h3>
              {/* <p>Edit Details</p> */}
            </div>
            <div className='LostItem_DetailDiv'>
              {lostItem?.id &&
                <p>
                  <b>Item ID:</b> {lostItem?.id}
                </p>}
              {lostItem?.manufacturer &&
                <p className='LostItem_Manufacturer'>
                  <b>Manufacturer: </b>
                  {lostItem?.manufacturer}
                </p>}
              {lostItem.description &&
                <>
                  <p>
                    <b>Description:</b>
                  </p>
                  <p className='LostItem_Description'>{lostItem?.description}</p>
                </>
              }
            </div>
            {user.id === lostItem.user_id && (
              <>
                <div className='LostItem_Divider' />
                <div className='LostItem_HistoryDiv'>
                  <p className='LostItem_History_H'>
                    <b>History</b>
                  </p>
                  {lostItem?.history?.slice()
                    .sort((a, b) => new Date(b?.date) - new Date(a?.date))
                    .map((item) => {
                      return (
                        <>
                          <div className='LostItem_History_P'>
                            <p>
                              <b>Item {item?.status}</b>
                            </p>
                            <p>
                              {new Date(item?.date).toLocaleTimeString()} |
                              {new Date(item?.date).toLocaleDateString()}
                            </p>
                          </div>
                          <div className='LostItem_History_P'>
                            <p>
                              <b className='locaion_p'>Scanned Location</b>
                            </p>
                            <div className='location_div'>
                              <p className='locaion_p'>{item?.location}</p>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  {/* <div className='LostItem_History_P'>
                <p style={{ color: '#831212' }}>
                  <b>Item Lost</b>
                </p>
                <p>08:55 PM | 06/30/22</p>
              </div> */}
                </div>
              </>
            )}
            <div className='LostItem_Divider' />
            <div className='LostItem_BtnDiv'>
              <p>
                This item is currently <span>{lostItem?.status}</span>.
              </p>
              {loggedIn && user.id === lostItem.user_id && (
                <div className='LostItem_FoundBtn'>
                  <BasicModal
                    btnText={lostItem?.status === "lost" ? 'Mark as found' : "Mark as lost"}
                    btnColor='#ffffff'
                    location
                    handleButton={handleFoundButton}
                  />
                </div>
              )}
              {user.id !== lostItem.user_id && (
                <div className='LostItem_ChatBtn'>
                  <BasicModal data={lostItem} btnText='Chat with owner' />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LostItem;
