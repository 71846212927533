import React from 'react'
import Navbar from '../common/Navbar'
import Detail from '../Detail'

const DetailPage = () => {
    return (
        <>
            <Navbar />
            <Detail />
        </>
    )
}

export default DetailPage