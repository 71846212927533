import './css/App.css';
import Axios from '../config/axios';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import EditModal from './EditModal';

const Detail = () => {
  const [singleData, setSingleData] = useState();
  const [open, setOpen] = useState(false);

  let { id } = useParams();

  const handleSingleData = () => {
    Axios.get(`/looser/items/${id}/`)
      .then((res) => {
        setSingleData(res?.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    handleSingleData();
  }, []);

  console.log(singleData, 'singleData');
  const handleFoundButton = () => {
    if (singleData?.status === 'lost') {
      Axios.post(`/looser/items/${singleData?.id}/mark-found/`)
        .then((res) => {
          toast.success(res?.data?.status);
          handleSingleData();
        })
        .catch((err) => console.log(err));
    } else {
      Axios.post(`/looser/items/${singleData?.id}/mark-lost/`)
        .then((res) => {
          toast.success(res?.data?.status);
          handleSingleData();
        })
        .catch((err) => console.log(err));
    }
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  console.log(singleData, 'singleData');
  return (
    <div className='main_layout'>
      <EditModal
        handleOpen={handleOpen}
        handleClose={handleClose}
        open={open}
        singleData={singleData}
      />
      <div className='all_images'>
        <div className='main_img_div'>
          <img src={singleData?.photos?.[0]?.photo} alt='' />
        </div>
        <div className='images_div'>
          {singleData?.photos
            ?.slice(1, singleData?.photos?.length)
            .map((item) => {
              return (
                <div key={item.id} className='img_div'>
                  <img src={item?.photo} alt='' />
                </div>
              );
            })}
        </div>
      </div>
      <div className='details_div'>
        <div className='name_detail_div'>
          <h3>{singleData?.name}</h3>
          <button onClick={handleOpen} className='details_edit'>
            Edit Details
          </button>
        </div>
        <div>
          {singleData?.manufacturer && (
            <div className='item_history_div'>
              <h2>Manufacturer: </h2>
              <p>{singleData?.manufacturer}</p>
            </div>
          )}
          {singleData?.serial_no && (
            <div className='item_history_div'>
              <h2>Serial Number: </h2>
              <p>{singleData?.serial_no}</p>
            </div>
          )}
          {singleData?.description && (
            <>
              <p>
                <b>Description:</b>
              </p>
              <p className='LostItem_Description'>{singleData?.description}</p>
            </>
          )}
        </div>
        <div className='divider'></div>
        {singleData?.history.length > 0 && (
          <div className='history_div'>
            <p className='LostItem_History_H'>
              <b>History</b>
            </p>
            {singleData?.history
              ?.slice()
              .sort((a, b) => new Date(b?.date) - new Date(a?.date))
              .map((item) => {
                return (
                  <>
                    <div className='LostItem_History_P'>
                      <p>
                        <b>Item {item?.status}</b>
                      </p>
                      <p>
                        {new Date(item?.date).toLocaleTimeString()} |
                        {new Date(item?.date).toLocaleDateString()}
                      </p>
                    </div>
                    <div className='LostItem_History_P'>
                      <p>
                        <b className='locaion_p'>Scanned Location</b>
                      </p>
                      <div className='location_div'>
                        <p className='locaion_p'>{item?.location}</p>
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
        )}
        <div className='status_div'>
          <p>
            This item is currently{' '}
            <span className={singleData?.status === 'lost' ? 'red' : 'blue'}>
              {singleData?.status}
            </span>
          </p>
        </div>
        <div className='status_div'>
          <button
            style={{
              backgroundColor:
                singleData?.status === 'found' ? 'red' : '#0a3f74',
            }}
            onClick={handleFoundButton}
            className='found_btn'
          >
            Mark as{' '}
            <span>{singleData?.status === 'found' ? 'lost' : 'found'}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Detail;
