import React, { useEffect } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import Login from './Login';
import mixpanel from 'mixpanel-browser';

const Store = () => {
  useEffect(() => {
    mixpanel.track('Store Page', {
      pageName: 'Store',
    });
  }, []);

  const { user } = useAuthenticator((context) => [
    context.user,
    context.authStatus,
  ]);
  if (user) {
    // Get info from the JWT
    const cognitoSession = user.getSignInUserSession();
    const xecurifyCustomerID = process.env.REACT_APP_SHOPIFY_SSO_CUSTOMER_ID; // Provided by vendor

    // Specify the xecurify user store based on environment.
    const idpIdentifier = process.env.REACT_APP_SHOPIFY_USER_STORE_ID;

    const jwtToken = cognitoSession.getIdToken().jwtToken;
    const storeDomain = process.env.REACT_APP_SHOPIFY_STORE_DOMAIN;

    const shopifyRedirectURL = `https://store.xecurify.com/moas/broker/login/jwt/callback/${xecurifyCustomerID}/${idpIdentifier}/${jwtToken}?relay=https://store.xecurify.com/moas/broker/login/shopify/${storeDomain}/index`;

    // Redirect to shopify URL
    window.location.href = shopifyRedirectURL;
    return null;
  } else {
    const shopifyRedirectURL = `https://store.qrtag.it/index`;
    window.location.href = shopifyRedirectURL;
    return null;
  }
};

export default Store;
