import React, { useEffect, useState } from 'react'
import Navbar from '../common/Navbar'
import ItemBox from '../ItemBox'
import Axios from '../../config/axios'
import { useNavigate } from 'react-router-dom'
import mixpanel from 'mixpanel-browser'


const MyStuff = () => {
  const [allData, setAllData] = useState([])
  const nav = useNavigate()

  const handleMyStuffData = async () => {
    const { data } = await Axios.get('/looser/items/')
    if (data) {
      setAllData(data)
    }
  }
  useEffect(() => {
    handleMyStuffData()
    mixpanel.track('My Stuff Page', {
      pageName: "My Stuff"
    });
  }, [])

  const handleItemClick = (id) => {
    nav(`/mystuff/${id}`)
  }

  return (
    <>
      <Navbar />
      <h1 className='Items_heading'>My Items</h1>
      <div className='all_Items'>
        {allData.map((item) => {
          return <ItemBox src={item?.thumbnail} name={item?.name} key={item?.id} onClick={() => handleItemClick(item?.id)} />
        })}
      </div>
    </>
  )
}

export default MyStuff